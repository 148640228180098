import React from "react"
import ButtonAction from "../ui/Buttons/ButtonAction"
import Hero from "../ui/Hero/Hero"
import classnames from 'classnames';
import * as styles from './Questions.module.scss'
import { Colors } from "../../utils/Types";
import IconContactSvg from "../../assets/get-in-contact.svg";

type Props = {
    bgBefore?: Colors;
    bgAfter?: Colors;
    withFilterMode?: boolean;
    className?: any;
}

export default function Questions (props: Props) {
    const {bgBefore, bgAfter, withFilterMode, className} = props;
    return (
        <Hero
            hasWaveBottom={true}
            hasWaveTop={true}
            waveBottomColor={bgAfter ? bgAfter : "white"}
            waveTopColor={bgBefore ? bgBefore : undefined}
            color={"dark-blue"}
            backgroundDotted={true}
            isExpandingRightOnly={(withFilterMode) ? "IsOneThird" : undefined}
            className={className}
        >
            <div className={styles.wrapper}>
                <div className={classnames(["columns", styles.columns])}>
                    <div className={classnames(["column", "is-hidden-touch", styles.stripesBg])}>

                    </div>
                    <div className="column">
                        <div className="content">
                            <p className="title">
                                Sie haben Fragen?<br />
                                Unser Team ist für Sie da!
                            </p>

                            <ButtonAction to="/kontakt">
                                Kontaktieren Sie uns
                            </ButtonAction>
                        </div>
                    </div>

                    <div className={classnames(["column", "is-4", "is-one-fifth-tablet", styles.contactIcon])}>
                        <div className="content has-text-right">
                            <img src={IconContactSvg} />
                        </div>
                    </div>
                </div>
            </div>
        </Hero>
    )
}
