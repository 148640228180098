// extracted by mini-css-extract-plugin
export var headerImage = "Unternehmen-module--header-image--2C6Su";
export var headerClaim = "Unternehmen-module--header-claim--2TGVO";
export var history = "Unternehmen-module--history--3lyId";
export var historyLabel = "Unternehmen-module--history-label--8w9zY";
export var historyIcon = "Unternehmen-module--history-icon--3YnEP";
export var historyLine = "Unternehmen-module--history-line--2U3av";
export var row1 = "Unternehmen-module--row1--3UtyP";
export var row2 = "Unternehmen-module--row2--24oxu";
export var row3 = "Unternehmen-module--row3--p4xZJ";
export var column = "Unternehmen-module--column--3KpO2";
export var employeeWrapper = "Unternehmen-module--employee-wrapper--1BDD-";
export var spinAround = "Unternehmen-module--spinAround--3uqUE";