
import React from "react"
import Layout from "../../components/Layout"
import Hero from "../../components/ui/Hero/Hero";
import Section from "../../components/ui/Section/Section";
import { graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import classnames from 'classnames';
import * as styles from './Unternehmen.module.scss';
import StuffCard from '../../components/StaffCard/StaffCard';
import Questions from '../../components/blocks/Questions';
import HorizontalScroll from "../../components/ui/HorizontalScroll/HorizontalScroll";
import UnternehmenIcon1 from "../../assets/unternehmen_1989.svg";
import UnternehmenIcon2 from "../../assets/unternehmen_2009.svg";
import UnternehmenIcon3 from "../../assets/unternehmen_bis-heute.svg";
import Versprechen1 from "../../assets/qualität.svg";
import Versprechen2 from "../../assets/know-how.svg";
import Versprechen3 from "../../assets/problemlöser.svg";
import SvgImage from "../../components/SvgImage";

export default ({ data }) => {

    const getImage = (filename: string) => data.mitarbeiter.edges.find((item) => item.node.relativePath.includes(filename)).node
   
    return (
        <Layout crumbs={[{ label: "Home", url: "" }, { label: "Unternehmen", url: "unternehmen" }]} headerColor="dark-blue" hasWave={false}>

            <Hero
                hasWaveBottom={true}
                color="dark-blue"
                title="Über Uns"
                className="is-hidden-desktop"
                subtitle="Die Firma HIS Print & Service, Ihr Partner für Schilder, Aufkleber, Textilien und Werbetechnik"
            >

            </Hero>


            <Section size={"small"} className="is-hidden-mobile">
                <div className="columns is-vcentered">
                    <div className="column is-one-third">
                        <span className="is-title is-size-2 is-uppercase">Über Uns</span>
                        <h1 className="subtitle">Die Firma HIS Print & Service, Ihr Partner für Schilder, Aufkleber, Textilien und Werbetechnik</h1>
                    </div>
                    <div className="column">
                        <div className={classnames(styles.headerImage)}>
                            <GatsbyImage
                                image={{...data.header.childImageSharp.gatsbyImageData, aspectRatio: 2}}
                                alt="Die Firma HIS Print & Service" />
                        </div>

                        <div className={classnames(styles.headerClaim)}>
                            <p>
                                Was uns auszeichnet:
                                Qualität - Flexibilität und Zuverlässigkeit!
                            </p>
                        </div>
                        
                    </div>


                </div>
            </Section>

            <Section size="small">
                <div className="content">
                    <h2 className="title">Unsere Geschichte</h2>
                    <span className="subtitle">Ihr solider und bodenständiger Partner</span>
                </div>
                
                <HorizontalScroll>
                    
                    <div className="columns is-mobile  is-8 is-variable">
                        
                        <div className="column">
                            <div className={styles.history}>
                                <hr className={styles.historyLine} />
                                <div className={styles.historyIcon}>
                                    <SvgImage  src={UnternehmenIcon1} alt="Firmengründung von HIS Print & Service im Jahr 1989" ratio={1} />
                                </div>
                                <div className={styles.historyLabel}>
                                    1989
                                </div>
                            </div>
                            
                            <div className="content">
                                <h4>Firmengründung</h4>
                                <p>
                                    In diesem Jahr wurde durch Willi Hoffmann ein Handelsunternehmen gegründet mit der Firmierung Hoffmann Industrieservice (HIS). Bis zum Jahr 1993 wurde das Unternehmen nebenberuflich geführt. Ab 1993 war das kleine Unternehmen in Ichenhausen in der Straße Heidenbühl ansässig.
                                </p>
                            </div>
                        </div>

                        <div className="column">
                            <div className={styles.history}>
                                <div className={styles.historyIcon}>
                                    <SvgImage  src={UnternehmenIcon2} alt="Vergrößerung des Gebäudes" ratio={1} />
                                </div>
                                <div className={styles.historyLabel}>
                                    2009
                                </div>
                            </div>
                            <div className="content">
                                <h4>Umzug und Vergrößerung</h4>
                                <p>
                                    Schließlich wurden die Räumlichkeiten zu klein und ein Umzug in einen ehemaligen Supermarkt wurde angestrebt. Im Jahr 2006 waren wir in Ichenhausen Günzburger Str. beheimatet. Im Jahr 2008 wurde unsere Halle in Ellzee geplant und im Oktober 2009 fertiggestellt. 
                                </p>
                            </div>
                        </div>

                        <div className="column">
                            <div className={styles.history}>
                                <div className={styles.historyIcon}>
                                    <SvgImage  src={UnternehmenIcon3} alt="Eigene Halle und größeres Gebäude" ratio={1} />
                                </div>
                                
                                <div className={styles.historyLabel} style={{fontWeight: "bold", left: "10%"}}>
                                    Bis heute
                                </div>
                            </div>
                            <div className="content">
                                <h4>Die Firma</h4>
                                <p>
                                    Seither sind wir hier auf mehr als 1000 qm untergebracht. Nun haben wir optimale Möglichkeiten auch Großprojekte zu realisieren. Mit unserem jungen und dynamischen Team stehen uns viele Möglichkeiten offen. Wir sind ein zuverlässiger Partner für alle Kunden.
                                </p>
                            </div>
                        </div>
                    </div>

                </HorizontalScroll>

            </Section>

            <Section  hasTitle={true}>
                    <div className="columns is-mobile">
                        <div className="column is-1 is-hidden-touch"></div>
                        <div className="column">
                            <div className="content">
                                <h2 className="title">Ein Team</h2>
                                <span className="subtitle">von Anfang bis Ende in guter Hand</span>
                            </div>
                        </div>
                        <div className="column is-1 is-hidden-touch"></div>
                    </div>
                    
                        <div>
                                <div className={classnames(["columns", styles.row1])}>
                                    <div className={classnames(["column", styles.column])}>
                                        <StuffCard 
                                            image={getImage("petra-wand.jpg" )}
                                            imageHover={getImage("petra-hover.jpg" )}
                                            name="Petra Schwarz" 
                                            jobTitle="Stellvertretende Geschäftsführung" 
                                            email="p.schwarz@hisprint.de" 
                                        />
                                    </div>
                                    <div className={classnames(["column", "is-one-third-desktop", styles.column])}>
                                        <StuffCard image={getImage("willi-wand.jpg")} imageHover={getImage("willi-hover.jpg" )} name="Willi Hoffmann" jobTitle="Geschäftsführer" email="info@hisprint.de" size="large"/>
                                    </div>
                                    <div className={classnames(["column", styles.column])}>
                                        <StuffCard image={getImage("johannes-wand.jpg")} imageHover={getImage("johannes-hover.jpg" )} name="Johannes Hoffmann" jobTitle="Junior" email="j.hoffmann@hisprint.de" color="dark-blue" />
                                    </div>
                                    <div className={classnames(["column", styles.column])}>
                                        <StuffCard image={getImage("barbara-wand.jpg")} imageHover={getImage("barbara-hover.jpg" )} name="Barbara Rodriguez" jobTitle="Auftragsabwicklung" email="b.rodriguez@hisprint.de" />
                                    </div>
                                </div>


                                <div className={classnames(["columns", styles.row2])}>
                                    <div className={classnames(["column", styles.column])}>
                                        <StuffCard image={getImage("hannah-wand.jpg" )} imageHover={getImage("hannah-hover.jpg")} name="Hannah Jehle" jobTitle="Auftragsabwicklung" email="h.jehle@hisprint.de"/>
                                    </div>
                                    <div className={classnames(["column", styles.column])}>
                                        <StuffCard image={getImage("mehmet-wand.jpg")} imageHover={getImage("mehmet-hover.jpg")} name="Mehmet Simsek" color="dark-blue" jobTitle="Produktion" email="" />
                                        
                                    </div>
                                    <div className={classnames(["column", "is-one-third-desktop", styles.column])}>
                                        <StuffCard image={getImage("selina-wand.jpg")} imageHover={getImage("selina-hover.jpg")} name="Selina Huber" jobTitle="Gestaltung" email="s.huber@hisprint.de" size="large"/>
                                    </div>
                                    <div className={classnames(["column", styles.column])}>
                                        <StuffCard image={getImage("needyou-wand.jpg")} imageHover={getImage("needyou-wand.jpg")} email="e.hallmann@hisprint.de" name="Elina Hallmann" jobTitle="Auszubildende" />
                                    </div>
                                </div>

                            <div className={classnames(["columns", styles.row3])}>
                                <div className={classnames(["column", "is-one-third", styles.column])}>
                                    <Link to="/unternehmen/jobs-karriere">
                                        <StuffCard image={getImage("needyou-wand.jpg")} imageHover={getImage("needyou-hover.jpg")} name="Platz für Dich" jobTitle="Wir sind immer auf der Suche nach neuen Kolleginnen und Kollegen!" email="bewerbung@hisprint.de" color="link"/>
                                    </Link>
                                </div>

                                <div className={classnames(["column", styles.column])}>
                                    <StuffCard image={getImage("emine-wand.jpg")} imageHover={getImage("emine-hover.jpg")} email="" name="Emine Cinar" jobTitle="Produktion" />
                                </div>
                                
                                <div className={classnames(["column", styles.column])}>
                                    <StuffCard image={getImage("barbara-s-wand.jpg")} imageHover={getImage("barbara-s-hover.jpg")} name="Barbara Stöckle" jobTitle="Produktion" email="" />
                                </div>
                                <div className={classnames(["column", styles.column])}>
                                    <StuffCard image={getImage("martina-wand.jpg")} imageHover={getImage("martina-hover.jpg")} email="" name="Martina Hoffmann" jobTitle="Produktion" color="dark-blue" />
                                </div>
                            </div>
                            
                            
                           
                        </div>


                
            </Section>

            <Questions bgAfter="white" />

            <Section  hasTitle={true}>
                <div className="content">
                    <h2>Unser Versprechen</h2>
                </div>
                <HorizontalScroll>
                    <div className="columns is-mobile is-8 is-variable">
                        <div className="column">
                            <div className={styles.history}>
                                <div className={styles.historyIcon}>
                                    <SvgImage  src={Versprechen1} alt="Unser Versprechen beste Qualität" ratio={1} />
                                </div>
                            </div>
                            <div className="content">
                                <h4>Qualität</h4>
                                <p>
                                    Zu guter Kundenzufriedenheit gehört eine gute Qualität. Regelmäßige Investitionen in neue und etablierte Technologien macht uns zum Qualitätsführer in diesem Bereich.
                                </p>
                            </div>
                        </div>

                        <div className="column">
                            <div className={styles.history}>
                                <div className={styles.historyIcon}>
                                    <SvgImage  src={Versprechen2} alt="Unser Versprechen Unser Know-How zu bieten" ratio={1} />
                                </div>
                            </div>
                            <div className="content">
                                <h4>Know-How</h4>
                                <p>
                                    Wir sind Profis in dem was wir täglich tun und kennen uns voll und ganz in unserer Materie aus. Die Spezialisierung auf Sonderanfertigungen gelingt durch täglichen Gebrauch dieses Wissens um die Wünsche unserer Kunden optimal zu bedienen.
                                </p>
                            </div>
                        </div>

                        <div className="column">
                            <div className={styles.history}>
                                <div className={styles.historyIcon}>
                                    <SvgImage  src={Versprechen3} alt="Unser Versprechen Problemlöser zu sein" ratio={1} />
                                </div>
                            </div>
                            <div className="content">
                                <h4>Problemlöser</h4>
                                <p>
                                    Unser Wissen angewendet ist es Teil unserers Services, was sich auch in unserem Namen verankert. Wir lösen Ihre Probleme im Bereich Beschriftung und Werbetechnik damit Sie erfolgreich sind und wir einen zufriedenen Kunden haben.
                                </p>
                            </div>
                        </div>
                    </div>
                </HorizontalScroll>
            </Section>


        </Layout>
    );
};


export const pageQuery = graphql`{
  header: file(relativePath: {regex: "/HIS-Unternehmen/"}) {
    relativePath
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  mitarbeiter: allFile(filter: {relativePath: {regex: "/mitarbeiter/"}}) {
    edges {
      node {
        relativePath
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  }
}
`