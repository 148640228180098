import React from 'react';
import * as styles from './StaffCard.module.scss';
import classnames from 'classnames';
import { Colors } from '../../utils/Types';
import { GatsbyImage } from "gatsby-plugin-image";


type Props = {
    name: string;
    jobTitle: string;
    email: string;
    size?: "normal" | "large";
    color?: Colors;
    image: any;
    imageHover?: any;
}

export default function StaffCard (props: Props) {
    const {name, jobTitle, email, color, image, imageHover} = props;

    const textColor = (color === "dark-blue" || color === "link" || color === "primary") ? "has-text-white" : "";

    return (
        <div className={classnames([styles.card, color ? "has-background-" + color : "has-background-light", textColor])}> 
            <div className={styles.cardImage} >
                {image && <GatsbyImage
                    image={image.childImageSharp.gatsbyImageData}
                    className={(imageHover) ? styles.main : ""}
                    alt={name} />}
                {imageHover && <GatsbyImage
                    image={imageHover.childImageSharp.gatsbyImageData}
                    className={styles.hoverIt}
                    alt={name} />}
            </div>
            <div className="content">
                <h3 className={classnames([textColor, styles.cardTitle])}>{name}</h3>
                <span>
                    {jobTitle}<br />
                    {email}
                </span>
            </div>
        </div>
    );
}