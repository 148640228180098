import classNames from "classnames";
import React from "react";
import {wrapper, image} from "./SvgImage.module.scss";

type Props = {
    alt: string;
    src: string;
    ratio: number;
    className?: string;
}

export default function SvgImage (props: Props) {
    const { alt, src, ratio, className } = props;

    return <div className={classNames([wrapper, className])}>
        
        <div style={{paddingTop: 100 * ratio + "%"}}>
        </div>

        <img className={image} width="100%" src={src} alt={alt} />
    </div>
}