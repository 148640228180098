import React from 'react';
import * as styles from './HorizontalScroll.module.scss';
import classnames from 'classnames';
import './HorizontalScroll.scss';
import { Breakpoints } from '../../../utils/Types';

type Props = {
    children: any;
    breakpoints?: [Breakpoints] | Breakpoints;
    itemsOnWidth?: 1 | 2 | 3 | 4;
}

export default function HorizontalScroll (props: Props) {
    const { children, itemsOnWidth } = props;
    const breakpoints = (props.breakpoints) ? Array.isArray(props.breakpoints) ? props.breakpoints : [props.breakpoints] : [];

    return <div className={classnames([
            styles.wrapper, 
            "horizontal-scroll", 
            ...breakpoints.map(bp => styles[bp+"Wrapper"]),
            itemsOnWidth ? "has-width-" + itemsOnWidth : "",
        ])}>
            {children}
    </div>
}